@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #24335d;
  --light: #52bdc8;
  --dark: #eb477d;
  --secondary: #eeb9d1;
  --gray-light: #acb2b8;
  --neutral-medium: #808485;
  --neutral-lighter: #d3d7da;
  --neutral-dark: #495056;
  --neutral: #f5f5f5;
}

::selection {
  background-color: var(--light);
  color: white !important;
}

a:link {
  text-decoration: none !important;
}

body {
  font-family: "Poppins";
  background-color: var(--neutral);
}
